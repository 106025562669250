import { cookie } from '@devportal/common';
const { API_ENDPOINT, AUTH_PROVIDER: AUTH_PROVIDER_ENDPOINT }: any = fetch('/envConfig.json').then((res) => res.json());

const apiUrl = API_ENDPOINT || process.env.REACT_APP_API_URL || 'https://developer-hub-api.highbond-s3.com';
const AUTH_PROVIDER = AUTH_PROVIDER_ENDPOINT || 'https://oidc.highbond-s3.com';

const fetchCodeSampleList = async ({ queryKey }) => {
  const { filter, searchText, page, recordsPerPage } = queryKey[1];
  let queryParam = filter?.length ? `?filter_ids=${filter}` : '';

  if (searchText?.length) queryParam += `${queryParam?.length ? '&' : '?'}search_text=${searchText}`;
  if (recordsPerPage?.length) queryParam += `${queryParam?.length ? '&' : '?'}records_per_page=${recordsPerPage}`;
  if (page?.length && recordsPerPage?.length) {
    const offset = (Number(page) - 1) * recordsPerPage;
    queryParam += `${queryParam?.length ? '&' : '?'}offset=${String(offset)}`;
  }

  return (
    await fetch(`${apiUrl}/code_samples${queryParam}`, {
      method: 'GET',
    })
  ).json();
};

const getFilters = async ({ queryKey }) => {
  const { filterPage } = queryKey[1];
  return (
    await fetch(`${apiUrl}/filters?filter_page=${filterPage}`, {
      method: 'GET',
    })
  ).json();
};

const getFeaturedCodeSamples = async () =>
  (
    await fetch(`${apiUrl}/featured_code_samples`, {
      method: 'GET',
    })
  ).json();

const getCodeSampleDetails = async ({ queryKey }) => {
  const { sampleId } = queryKey[1];
  const token = cookie.get('accessToken');

  return (
    await fetch(`${apiUrl}/code_samples/${sampleId}`, {
      method: 'GET',
      headers:
        token && token?.length
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
    })
  ).json();
};

const getSignedUrl = async ({ queryKey }) => {
  const { filePath } = queryKey[1];

  return (
    await fetch(`${apiUrl}/signed_url`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        data: {
          type: 'signed_url',
          attributes: {
            filePath,
          },
        },
      }),
    })
  ).json();
};

const fetchIdeaList = async ({ queryKey }) => {
  const { filter, searchText, page, recordsPerPage, has_scripts } = queryKey[1];
  let queryParam = filter?.length ? `?filter_ids=${filter}` : '';

  if (searchText?.length) queryParam += `${queryParam?.length ? '&' : '?'}search_text=${searchText}`;
  if (has_scripts?.length) queryParam += `${queryParam?.length ? '&' : '?'}has_scripts=${has_scripts}`;
  if (recordsPerPage?.length) queryParam += `${queryParam?.length ? '&' : '?'}records_per_page=${recordsPerPage}`;
  if (page?.length && recordsPerPage?.length) {
    const offset = (Number(page) - 1) * recordsPerPage;
    queryParam += `${queryParam?.length ? '&' : '?'}offset=${String(offset)}`;
  }

  return (
    await fetch(`${apiUrl}/ideas${queryParam}`, {
      method: 'GET',
    })
  ).json();
};

const getFeaturedIdeas = async () =>
  (
    await fetch(`${apiUrl}/featured_ideas`, {
      method: 'GET',
    })
  ).json();

const getIdeaDetails = async ({ queryKey }) => {
  const { ideaId } = queryKey[1];
  const token = cookie.get('accessToken');

  return (
    await fetch(`${apiUrl}/ideas/${ideaId}`, {
      method: 'GET',
      headers:
        token && token?.length
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
    })
  ).json();
};

export {
  apiUrl,
  AUTH_PROVIDER,
  fetchCodeSampleList,
  getFilters,
  getFeaturedCodeSamples,
  getCodeSampleDetails,
  getSignedUrl,
  fetchIdeaList,
  getFeaturedIdeas,
  getIdeaDetails,
};
